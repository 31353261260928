<template>
	<div>
    <div>
      <Loader v-if="!shift || !shift.id" />
      
		  <div class="dashboard__container--body" v-if="shift && shift.id && event && (!shift.type || (shift.type != 'Permanent' && shift.type != 'Job'))" >
		  	<div class="dashboard__container--body--col" style="width:100%">
		  		<div class="flex mt-3 flex-column">
		  			<div class="flex flex-column">
		  				<div class="mb-3">
	    					<label for="shiftName">Shift Title (ex: Night Shift Janitor):</label>
	    					<input type="text" v-model.trim="shift.title" id="shiftName" required />
	    				</div>
	    				<div class="mb-4" style="max-width: 300px;">
	              <label for="rep">Application Status:</label>
	              <v-select
	                class="mt-2"
	                :options="statuses"
	                v-model="shift.staffingStatus"
	                >
	              </v-select>
	  					</div>
	    				<div class="mb-3">
		    				<a :href="`https://jumpstaffing.com/form/` + $route.params.is" target="_blank">
				          <button class="btn btn__small btn__outlined mr-3 mb-2">Web Form</button>
				        </a>
				      </div>
			  			<div class=" flex flex-row">
			  				<div class=" flex flex-row flex-wrap">
				  				<div class="pr-3 mb-3" v-if="event && event.jobs">
				  					<label for="day">Set Default Job for Shift:</label>
										<v-select
				              label="title" 
				              :options="event.jobs"
				              v-model="shift.position"
				              style="min-width: 180px;"
				              >
				            </v-select>
				          </div>
				          
				          <div class="pr-3 mb-3" v-if="event && event.venue">
				          	<label for="day">Select day:</label>
				            <v-select
				              label="title" 
				              :options="event.days"
				              v-model="shift.day"
				              style="min-width: 180px;"
				              >
				            </v-select>
				          </div>
				          <div class="pr-3 mb-3"  style="width: 180px;">
				            <label for="staff">Total Staff Requested:</label>
				            <input type="number" v-model.trim="shift.staff" id="staff"  />
				          </div>
				          <div class="pr-3 mb-3" v-if="(eventInfo && (!eventInfo.shiftType || eventInfo.shiftType == 'Gig') && (eventInfo.groups && eventInfo.groups.length >= 1))">
		  							<label for="notification">Send New Shift Notification to Group:</label>
		  							<div>
			            	<input type="checkbox" v-model.trim="shift.sendNotification" id="notification" />
			            	</div>
		  						</div>
				        </div>
			          <div v-if="event" class="floating_buttons"  style="background: transparent;">
			          	<!-- <router-link :to="`/orders/${$route.params.id}/placement`">
			          		<button class="btn btn__small btn__outlined mr-3">Placements</button>
			          	</router-link> -->
				          
				          <button v-if="(!showDelete || showDelete == false)" class="btn btn__danger " @click="showDeleteShift(shift)">Delete</button>
				          <button v-if="showDelete == true" class="btn  btn__primary ml-3" @click="cancelDeleteShift(shift)">cancel</button>
				          <button v-if="showDelete == true" class="btn  btn__danger ml-3" @click="deleteShift(shift)">Yes Actually Delete</button>
				          <button v-if="(!shift.showDelete || shift.showDelete == false)" class="btn ml-5 btn__primary" @click="updateShift(shift)" :disabled="event.cancelled">
				          	Update
				          	<transition name="fade">
			                <span class="ml-2" v-if="performingRequest">
			                <i class="fa fa-spinner fa-spin"></i>
			                </span>
			              </transition>
				          </button>
			          </div>
		          </div>
		          
		          
				    
			        <div class="flex mb-3" v-if="event">
	  						<div>
		            <label for="start">Default Start Time:</label>
			            <input type="time" v-model.trim="shift.startTime" id="start" />
			          </div>
			          <div class="pl-3">
			            <label for="end">Default End Time:</label>
			            <input type="time" v-model.trim="shift.endTime" id="end" />
			          </div>

	  					</div>
	  					<div class="mb-3" style="max-width:280px;">
			          	<label for="shiftPo">PO Number:</label>
			          	<input type="text" v-model.trim="shift.poNumber" id="shiftPo">
			          </div>
	  					<!-- <div>
		  					<div class="mb-3" style="max-width: 300px;">
		              <label for="rep">Required Certifications:</label>
		              <v-select
		                class="mt-2"
		                :options="certs"
		                v-model="shift.certs"
		                multiple
		                >
		              </v-select>
		  					</div>
	  					</div> -->
			        <div class="mb-3">
		            <label for="details">Details from Client:</label>
		            <textarea name="details" id="details" cols="30" rows="1" v-model.lazy="shift.details" style="background: #efefef;"></textarea>
		          </div>
		          <div class="mb-3">
		            <label for="info">Shift Info for Emails:</label>
		            <textarea name="info" id="info" cols="30" rows="1" v-model.lazy="shift.info" style="background: #efefef;"></textarea>
		          </div> 
		          <div class="mb-3">
            	<h5>Attach Shift Files</h5>
              <label for="fileTitle">Details:</label>
              <input class="mb-2" placeholder="File Title" type="text" v-model.trim="fileTitle" id="fileTitle" />
              <textarea placeholder="File Description" name="fileDescription" id="fileDescription" cols="30" rows="1" v-model="fileDescription"></textarea>

              <input class="mt-3" type="file" ref="fileInputTip" accept="image/*,application/pdf,.doc" @change="previewImage">
              <progress :value="uploadValue" max="100" v-if="showBar"></progress>
              <div class="mb-3">
                <button v-if="imageData != null" class="btn btn__primary mt-3" @click="onUploadFile">
                  Upload
                  <transition name="fade">
                    <span class="ml-2" v-if="performingRequest3">
                    <i class="fa fa-spinner fa-spin"></i>
                    </span>
                  </transition>
                </button>
              </div>
              <div v-if="shift.files && shift.files.length >= 1">
                <vue-good-table
                  :columns="columns"
                  :rows="shift.files"
                  >
                  <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field == 'url'">
                      <a :href="props.row.url" target="_blank"><i class="fas fa-external-link"></i></a>
                    </span>
                    <span v-else-if="props.column.field == 'extras'">
                      <button @click="deleteUploadedFile(props.row, props.index)">
                        <i class="fas fa-trash"></i>
                      </button>
                    </span>
                    <span v-else>
                      {{props.formattedRow[props.column.field]}}
                    </span>
                  </template>
                </vue-good-table>
              </div>
		        </div> 
		        </div>
		  		</div>
		  	</div>
		  </div>
		  <div class="dashboard__container--body" v-if="shift && shift.type && (shift.type != 'Permanent' && shift.type != 'Job')">
		  	<div class="dashboard__container--body--col">
		  		<div>
		  			<div class="mb-3">
    					<label for="invoiced">Invoice Number:</label>
    					<input type="text" v-model.trim="shift.invoiceNumber" id="invoiced" />
    				</div>

		  		</div>
		  	</div>
		  	<div class="dashboard__container--body--col">
		  		<div>
		  			<div class="mb-3">
    					<label for="time">Time Entered:</label>
    					<input type="checkbox" v-model="shift.timeEntered" id="time" class="ml-2" />
    				</div>
		  			<div class="mb-3">
    					<label for="paid">Payroll Complete:</label>
    					<input type="checkbox" v-model="shift.paid" id="paid" class="ml-2" />
    				</div>
    				<div class="mb-3">
    					<label for="factored">Factored:</label>
    					<input type="checkbox" v-model="shift.factored" id="factored" class="ml-2" />
    				</div>
		  			
		  		</div>
		  	</div>
		  </div>
		  <div class="dashboard__container--body" v-if="shift && shift.type && (shift.type == 'Permanent' || shift.type == 'Job')">
		  	<div class="dashboard__container--body--col">
  				<div class="flex flex-column mb-5">
  					<div class="mb-3">
    					<label for="shiftName">Shift Title (ex: Night Shift Janitor):</label>
    					<input type="text" v-model.trim="shift.title" id="shiftName" required />
    				</div>
    				<div class="mb-3">
	    				<a :href="`https://jumpstaffing.com/form/` + $route.params.is" target="_blank">
			          <button class="btn btn__small btn__outlined mr-3 mb-2">Web Form</button>
			        </a>
			      </div>
			      <div class="mb-4" style="max-width: 300px;">
              <label for="rep">Application Status:</label>
              <v-select
                class="mt-2"
                :options="statuses"
                v-model="shift.staffingStatus"
                >
              </v-select>
  					</div>
  					<div class="mb-3" v-if="event && event.jobs" style="max-width: 300px;">
	  					<label for="day">Set Default Job for Shift:</label>
							<v-select
	              label="title" 
	              :options="event.jobs"
	              v-model="shift.position"
	              style="min-width: 180px;"
	              >
	            </v-select>
	          </div>
	          <div class="flex mb-3">
	  					<div v-if="event && event.days">
  							<label for="day">Start Date:</label>
		            <input type="date" v-model.trim="shift.day" id="day" />
		          </div>
		        </div>
	          <div class="mb-3" style="width: 180px;">
	            <label for="staff">Staff Requested:</label>
	            <input type="number" v-model.trim="shift.staff" id="staff"  />
	          </div>
	          <div class="mb-3" style="width:280px;">
    					<label for="po">PO Number:</label>
    					<input type="text" v-model.trim="shift.poNumber" id="po" />
    				</div>
	          <!-- <div class="mb-3" style="max-width: 300px;">
              <label for="rep">Required Certifications:</label>
              <v-select
                class="mt-2"
                label="title" 
                :options="certs"
                v-model="shift.certs"
                multiple
                taggable
                push-tags
                >
              </v-select>
  					</div> -->
  					<!-- <div class="mb-3">
							<label for="notification">Send New Shift Notification to Group:</label>
							<div>
            	<input type="checkbox" v-model.trim="shift.sendNotification" id="notification" />
            	</div>
						</div> -->
						<div class="mb-3">
		            <label for="details">Shift-Specific Details or Job Description:</label>
		            <textarea name="details" id="details" cols="30" rows="3" v-model.lazy="shift.details" style="background: #efefef;"></textarea>
		          </div>
  				</div>
  			</div>
  			<div class="dashboard__container--body--col">
  				<div class="flex flex-column mb-5">
		  					<div class="flex mt-3 flex-row">
		  						<div class="mr-5 flex align-center" style="width:80px;">
		  							<span class="caption strong">Sunday</span>
		  						</div>
		  						<div class="mr-5">
		  							<label for="start">Start Time:</label>
				            <input type="time" v-model.trim="shift.sundayStart" id="start" />
		  						</div>
		  						<div class="mr-5">
		  							<label for="end">End Time:</label>
				            <input type="time" v-model.trim="shift.sundayEnd" id="end" />
		  						</div>
		  					</div>
	  						<div class="flex mt-3 flex-row">
		  						<div class="mr-5 flex align-center" style="width:80px;">
		  							<span class="caption strong">Monday</span>
		  						</div>
		  						<div class="mr-5">
		  							<label for="start">Start Time:</label>
				            <input type="time" v-model.trim="shift.mondayStart" id="start" />
		  						</div>
		  						<div class="mr-5">
		  							<label for="end">End Time:</label>
				            <input type="time" v-model.trim="shift.mondayEnd" id="end" />
		  						</div>
		  					</div>
		  					<div class="flex mt-3">
		  						<div class="flex align-center mr-5" style="width:80px;">
		  							<span class="caption strong">Tuesday</span>
		  						</div>
		  						<div class="mr-5">
		  							<label for="start">Start Time:</label>
				            <input type="time" v-model.trim="shift.tuesdayStart" id="start" />
		  						</div>
		  						<div class="mr-5">
		  							<label for="end">End Time:</label>
				            <input type="time" v-model.trim="shift.tuesdayEnd" id="end" />
		  						</div>
		  					</div>
		  					<div class="flex mt-3">
		  						<div class="flex align-center mr-5" style="width:80px;">
		  							<span class="caption strong">Wednesday</span>
		  						</div>
		  						<div class="mr-5">
		  							<label for="start">Start Time:</label>
				            <input type="time" v-model.trim="shift.wednesdayStart" id="start" />
		  						</div>
		  						<div class="mr-5">
		  							<label for="end">End Time:</label>
				            <input type="time" v-model.trim="shift.wednesdayEnd" id="end" />
		  						</div>
		  					</div>
		  					<div class="flex mt-3">
		  						<div class="flex align-center mr-5" style="width:80px;">
		  							<span class="caption strong">Thursday</span>
		  						</div>
		  						<div class="mr-5">
		  							<label for="start">Start Time:</label>
				            <input type="time" v-model.trim="shift.thursdayStart" id="start" />
		  						</div>
		  						<div class="mr-5">
		  							<label for="end">End Time:</label>
				            <input type="time" v-model.trim="shift.thursdayEnd" id="end" />
		  						</div>
		  					</div>
		  					<div class="flex mt-3">
		  						<div class="flex align-center mr-5" style="width:80px;">
		  							<span class="caption strong">Friday</span>
		  						</div>
		  						<div class="mr-5">
		  							<label for="start">Start Time:</label>
				            <input type="time" v-model.trim="shift.fridayStart" id="start" />
		  						</div>
		  						<div class="mr-5">
		  							<label for="end">End Time:</label>
				            <input type="time" v-model.trim="shift.fridayEnd" id="end" />
		  						</div>
		  					</div>
		  					<div class="flex mt-3">
		  						<div class="flex align-center mr-5" style="width:80px;">
		  							<span class="caption strong">Saturday</span>
		  						</div>
		  						<div class="mr-5">
		  							<label for="start">Start Time:</label>
				            <input type="time" v-model.trim="shift.saturdayStart" id="start" />
		  						</div>
		  						<div class="mr-5">
		  							<label for="end">End Time:</label>
				            <input type="time" v-model.trim="shift.saturdayEnd" id="end" />
		  						</div>
		  					</div>
		  				</div>
  			</div>
			</div>
	          

      <div v-if="event && shift" class="floating_buttons"  style="background: transparent;">
        
        <button v-if="(!showDelete || showDelete == false)" class="btn btn__danger " @click="showDeleteShift(shift)">Delete</button>
        <button v-if="showDelete == true" class="btn  btn__primary ml-3" @click="cancelDeleteShift(shift)">cancel</button>
        <button v-if="showDelete == true" class="btn  btn__danger ml-3" @click="deleteShift(shift)">Yes Actually Delete</button>
        <button v-if="(!shift.showDelete || shift.showDelete == false)" class="btn ml-5 btn__primary" @click="updateShift(shift)" :disabled="event.cancelled">
        	Update
        	<transition name="fade">
            <span class="ml-2" v-if="performingRequest">
            <i class="fa fa-spinner fa-spin"></i>
            </span>
          </transition>
        </button>
      </div> 
		</div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import ShiftNote from '@/components/Placements/ShiftNote.vue'
import router from '@/router'
const fb = require('../../../firebaseConfig.js')

export default {
	props: ['eventInfo'],
  name: 'orderShift',
  components: {
    Loader,
    ShiftNote
  },
  data() {
    return {
      performingRequest: false,
      showNewNote: false,
      newShift: {},
      showDelete: false,
      newName: '',
      performingRequest3: false,
      imageData: null,
	    fileTitle: '',
	    fileDescription: '',
	    uploadValue: 0,
	    showBar:false,
	    statuses: ['Open', 'Closed', 'On-Hold', 'Cancelled', 'Filled', ],
      columns: [
      {
        label: 'Title',
        field: 'title',
      },
      {
        label: 'Description',
        field: 'description',
      },
      {
        label: 'Url',
        field: 'url',
        tdClass: 'text-right',
      },
      {
        label: '',
        field: 'extras',
        tdClass: 'text-right',
      },
    ]
    }
  },
  created () {
    this.$store.dispatch("getShiftFromIdOnly", this.$route.params.is);
    if (!this.certs || this.certs.length < 1) {
      this.$store.dispatch("getCerts")
    }
  },
  mounted () {
    if (!this.jobs || this.jobs.length < 1) {
      this.$store.dispatch("getJobsState")
    }
  },
  computed: {
    ...mapState(['jobs', 'shift', 'certs']),
    event() {
      return this.eventInfo
    },
    eventId() {
    	return this.eventInfo.id
    },
    groupIDs() {
    	let idsArray = []
    	this.event.groups.forEach(group => {
    		idsArray.push(group.id)
    	})
    	return idsArray
    }
  },
  methods: {
  	previewImage(event) {
      this.uploadValue=0;
      this.imageData=event.target.files[0]
    },
    onUploadFile() {
      this.showBar = true
      let shift = this.shift
      let fileTitle = this.fileTitle
      let fileDescription = this.fileDescription
      let rand = (Math.random().toString(36).substring(2, 16) + Math.random().toString(36).substring(2, 16)).toUpperCase()
      let storageRef = fb.storageRef.child('docs/' + rand).put(this.imageData);
      storageRef.on(`state_changed`, snapshot => {
        this.uploadValue=(snapshot.bytesTransferred/snapshot.totalBytes)*100;
      }, error => {console.log(error.message)},
      () => {this.uploadValue=100;
        storageRef.snapshot.ref.getDownloadURL().then((downloadURL) => {
          console.log('File available at', downloadURL)
            var docRef = fb.shiftsCollection.doc(shift.id)
            docRef.update({
              files: fb.firestore.FieldValue.arrayUnion({
                title: fileTitle,
                description: fileDescription,
                url: downloadURL
              })
            });
            shift.files.push({
              title: fileTitle,
              description: fileDescription,
              url: downloadURL
            })
        })
        this.showBar = false
      })
      this.imageData = null
      this.fileTitle = ''
      this.fileDescription = ''
      this.$refs.fileInputTip.value=null
    },
    onFileTypeMismatch(file) {
      alert('Invalid file type. Please choose a jpeg or png file.')
    },
    onFileSizeExceed(file) {
      alert('Please choose a file smaller than 2MB')
    },
    updateShift(eventShift) {
    	console.log(eventShift)
    	this.performingRequest = true
    	// eventShift.location = this.event.venue.address.city + ', ' +  this.event.venue.address.state;
    	// eventShift.center = this.event.venue.center;
    	// eventShift.venue = this.event.venue.title;
    	// eventShift.groupIds = this.groupIDs
    	this.$store.dispatch("updateEventShift", eventShift)
    	setTimeout(() => {
        this.performingRequest = false
      }, 1000)
    },
    showDeleteShift(eventShift) {
    	this.showDelete = true
    	this.$store.dispatch("updateEventShift", eventShift)
    	// this.$store.dispatch("deleteEventDays", {
      // 	event: this.event.id
      // 	day: this.newShift.day
      // })
    },
    cancelDeleteShift(eventShift) {
    	this.showDelete = false
    	this.$store.dispatch("updateEventShift", eventShift)
    },
    deleteShift(eventShift) {
      console.log(eventShift)
      this.$store.dispatch("deleteShift", eventShift.id)
      let url = `/orders/` + this.$route.params.id + `/shifts/`
      router.push(url)
    },
  },
  beforeDestroy () {
  	this.$store.dispatch('clearJobsState')
  	this.performingRequest = null
  	delete this.performingRequest
  	this.newShift = null
  	delete this.newShift
  	this.showNewNote = null
  	delete this.showNewNote
  	this.showDelete = null
  	delete this.showDelete
  	this.newName = null
  	delete this.newName
  	delete this.deleteShift
  	delete this.cancelDeleteShift
  	delete this.showDeleteShift
  	delete this.updateShift
  }
}
</script>
